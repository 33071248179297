nav.conf-navbar{
    background: #fff;
    padding: 1rem;
    border-bottom: 1px solid #ddd;
    .calc_48{
        left: calc(50% - 48px) !important;
    }
    .calc_80{
        left: calc(50% - 80px) !important;
    }
    .aconf_logo {
        width: 108px;
        height: 34px;
    }
    .aconf_h5_logo {
        display: none;
        width: 34px;
        height: 34px;
    }
    .navbar-toggler {
        margin-left: .375rem;
        padding: .47rem .75rem;
    }
    .navbar-toggler-icon {
        width: 1em;
        height: 1em;
        display: block;
    }
    .select_lang{
        margin-right: 1.25rem;
        a{
            border-radius: 0;
            padding: 0px 5px;
            font-size: .875rem;
            color: #999;
            line-height: 1.375rem;
            background: transparent;
        }
        a.drop_menu_btn{
            border: 1px solid #ddd;
            display: block;
            
        }
        .sub_menu_hover{
            border: 1px solid #ddd;
            border-top: none;
            text-align: left;
        }
        a:hover{
            background: transparent;
            color: #009966;
        }
    }
    .m-search {
        display: none;
        .form-inline{
            width: 100%;
        }
    }
    .top_search{
        border: 1px solid #ddd;
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        display: block;
        width: 100%;
        input.form-control{
            border: none;
            font-size: .875rem;
            padding-right: 30px;
        }
        button{
            vertical-align: middle;
            display: inline-block;
            padding: 0px 8px;
            color: #999;
            border: none;
            background: transparent;
            width: auto;
            position: absolute;
            top: 7px;
            right: 0;
            z-index: 99;
        }
        button:focus{
            background: #f6f6f6;
            color: #009966;
        }
    }
    .navbar-collapse{
        .navbar-nav{
            .drop_menu_hover>a:after{
                color: #666;
                display: inline-block;
                margin-left: .255em;
                vertical-align: .255em;
                content: "";
                border-top: .255em solid;
                border-right: .255em solid transparent;
                border-bottom: 0;
                border-left: .255em solid transparent;
            }
            .drop_menu_hover>a:hover:after{
                color: #009966;
                display: inline-block;
                margin-left: .255em;
                vertical-align: middle;
                content: "";
                border-top: 0;
                border-right: .255em solid transparent;
                border-bottom: .255em solid;
                border-left: .255em solid transparent;
            }
            .nav-item{
                .sub_menu_hover{
                    list-style: none;
                    padding-inline-start: 0;
                    margin-bottom: 0;
                    display: none;
                    position: absolute;
                    left: 0;
                    z-index: 9999;
                    text-align: center;
                    border: none;
                    border-top: 2px solid #009966;
                    background-color: #dbf0e9;
                    li{
                        a{
                            background: transparent;
                        }
                        a:hover{
                            color: #fff;
                            background-color:#009966;
                        }
                    }
                }
                a{
                    color: #333;
                    padding: 0.5rem 1rem;
                }
                a:hover{
                    color: #009966;
                }
                a.nav-link{
                    position: relative;
                    padding: 1rem 1.5rem;
                }
            }
            .nav-item.active{
                a{
                    color: #009966;
                }
            }
        }
        .create_conf{
            position: relative;
            .dropdown-menu{
                text-align: center;
                font-size: .875rem;
                p{
                    a{
                        padding: 0 5px;
                    }
                    .fas{
                        color: #999;
                    }
                }
            }
        }
        .header-user-dropdown{
            position: relative;
            a.head-user-a {
                display: inline-block;
                color: #666;
                padding: 1rem 0;
                .icon-head-user {
                    margin-right: 3px;
                }
            }
            a.head-user-a:after{
                display: inline-block;
                margin-left: .255em;
                vertical-align: .255em;
                content: "";
                border-top: .255em solid;
                border-right: .255em solid transparent;
                border-bottom: 0;
                border-left: .255em solid transparent;
                color: #333;
            }
            ul {
                display: none;
                background-color: #dbf0e9;
                position: absolute;
                left: 0;
                right: 0;
                top: 100%;
                z-index: 1000;
                border-top: 2px solid #009966;
                li {
                    a {
                        width: 100%;
                        display: block;
                        color: #333;
                        text-align: center;
                        line-height: 40px;
                        margin: 0;
                    }
                    a:hover {
                        color: #fff;
                        background: #096;
                    }
                }
            }
        }
        .header-user-dropdown:hover{
            ul {
                display: block
            }
        }
    }
}
@media(min-width:992px){
    nav.conf-navbar .navbar-brand  {
        display: inline-block !important;
    }
}
@media(max-width:1200px) {
    nav.conf-navbar .navbar-collapse .navbar-nav .nav-item a.nav-link {
        padding: 1rem !important;
    }
}
@media(max-width:991px){
    nav.conf-navbar .navbar-toggler {
        float: right;
        margin-left: .175rem;
        padding: .47rem .375rem;
    }
    nav.conf-navbar .navbar-brand {
        margin-right: .175rem;
        float: left;
        padding-top: 0px;
        padding-bottom: 0;
    }
    nav.conf-navbar .navbar-collapse .navbar-nav .nav-item a.nav-link {
        padding: 1rem 0.5rem;
    }
    nav.conf-navbar .navbar-collapse .create_conf {
        margin-right: 0!important;
    }
    nav.conf-navbar .navbar-collapse .create_conf .dropdown-toggle {
        width: 100% !important;
    }
    nav.conf-navbar .select_lang {
        display: none;
    }
    nav.conf-navbar .calc_48{
        left: 0!important;
        right: 0;
    }
    nav.conf-navbar .calc_80{
        left: 0!important;
        right: 0;
    }
    nav.conf-navbar .navbar-collapse .navbar-nav .nav-item .sub_menu_hover {
        position: initial;
        text-align: left;
    }
    nav.conf-navbar .navbar-brand .aconf_logo {
        width: 66px;
        height: auto;
    }
    nav.conf-navbar .m-search {
        display: block;
        float: right;
    }
    .pc-search {
        margin-right: 0 !important;
        margin-bottom: 10px;
    }
    nav.conf-navbar>.container {
        display: block;
    }
    nav.conf-navbar {
        padding: 1rem 0.375rem;
        position: fixed;
        top: 0;
        z-index: 999;
        right: 0;
        left: 0;
    }
    body{
        padding-top: 70px;
    }
    .login_group a {
        display: block;
    }
    .login_group a {
        margin: 10px 0 !important;
    }
    nav.conf-navbar .navbar-collapse .header-user-dropdown ul {
        display: none;
        background-color: #dbf0e9;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        z-index: 1000;
        border-top: 2px solid #009966;
        overflow-x: hidden;
        overflow-y: auto;
        height: 280px;
    }
}
@media(max-width:325px){
    nav.conf-navbar .navbar-brand .aconf_h5_logo {
        display: block;
    }
    nav.conf-navbar .navbar-brand .aconf_logo {
        display: none;
    }
}