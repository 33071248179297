.va-error, .va-help {
    cursor: default;
    /* max-width: 300px; */
    width: auto !important;
    position: absolute !important;
    word-break: break-all;
    padding: 3px 5px;
    text-indent: 20px;
    border: 1px solid #ccc;
    background: #fff;
    color: red;
    border: 1px solid rgba(0,0,0,0.15);
    box-shadow: 0 3px 12px rgba(0,0,0,0.15);
    z-index: 99;
}

.va-error:before {
    border-width: 5px;
    z-index: 2;
    left: 17px;
    border-bottom-color: #fff;
}

.va-error:after, .va-error:before {
    position: absolute;
    left: 15px;
    border: solid transparent;
    border-bottom-color: #ddd;
    content: ' ';
    height: 0;
    width: 0;
    border-width: 6px;
    pointer-events: none;
    bottom: 100%;
}

.va-error:after {
    border-width: 7px;
    z-index: 1;
}


.va-error:after, .va-error:before {
    position: absolute;
    left: 15px;
    border: solid transparent;
    border-bottom-color: #ddd;
    content: ' ';
    height: 0;
    width: 0;
    border-width: 6px;
    pointer-events: none;
    bottom: 100%;
}

.ipt-error {
    border-color: red !important;
}

.va-error>i {
    position: absolute;
    background-image: url(https://file.aconf.org/public/images/icons/new/error_sm_red.png);
    background-position: 0px 0px;
    margin-right: 3px;
    top: 7px;
    background-size: 14px 14px;
    width: 14px;
    height: 14px;
    display: block;
    float: left;
}