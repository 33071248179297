body {
    color: #333;
    position: relative;
}
a {
    color: #009966;
}
a:hover {
    color: #24986d;
    text-decoration: none;
}
p {
    margin-bottom: 0.5rem;
}
ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}
button,input,select{
    outline: 0;
}
button:focus,input:focus,select:focus{
    outline: 0;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../../../media/icons/select_default_icon.png")!important;
    background-repeat: no-repeat !important;
    background-position: right center !important;
    padding-right: 15px !important;
}
.icon-iw {
    text-align: center;
    width: 1.25em;
    display: inline-block;
    line-height: 1;
}
.star {
    color: red;
}
.section {
    padding: 1.25rem 0;
}
.table-sm td, .table-sm th {
    padding: .45rem;
}
.web_bg {
    background: #f6f6f6 url("../../../media/icons/ptu.jpg") top center;
}
.blog-main {
    background: #fff;
    border: 1px solid #d1d1d1;
    padding: 10px;
    position: relative;
}
/* 重置单选和多选按钮颜色 */
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #009966;
    background-color: #009966;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #00af75;
    border-color: #00af75;
}
/* 分享组件样式 */
.share-item-container {
    position: absolute !important;
    top: 30px !important;
    right: 0px !important;
    border: 1px solid #dbdbdb !important;
    padding: 10px 30px 10px 10px !important;
    z-index: 9 !important;
    background: #fff !important;
    display: none;
    .bdsharebuttonbox {
        a {
            float: left !important;
            font-size: 1rem !important;
            padding-left: 0px !important;
            height: 40px !important;
            width: 40px !important;
            background-image: url(//file.aconf.org/public/images2.0/icons/share_icon40x40.png) !important;
            background-repeat: no-repeat !important;
            cursor: pointer !important;
            margin: 0 10px 0 0 !important;
            &.bds_qzone {
                background-position: 0 0 !important;
            }
            &.bds_tsina {
                background-position: 0 -40px !important;
            }
            &.bds_weixin {
                background-position: 0 -80px !important;
            }
            &.bds_fbook {
                background-position: 0 -120px !important;
            }
            &.bds_twi {
                background-position: 0 -160px !important;
            }
            &.bds_linkedin {
                background-position: 0 -200px !important;
            }
        }
    }
    .mod-share_closeBtn {
        position: absolute !important;
        right: 7px !important;
        top: 0px !important;
        a {
            color: #8e8e8e !important;
            font-size: 1.375rem !important;
            line-height: 1 !important;
        }
    }
}
/* 翻页样式 */
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
    li {
        display: inline-block;
        a {
            position: relative;
            padding: 6px 12px;
            margin: 0 2px;
            line-height: 1.42857143;
            color: #000;
            text-decoration: none;
            border: 1px solid transparent;
        }
        a:hover {
            background: #009966;
            color: #fff;
            border-color: #009966;
        }
        a.active {
            background: #009966;
            color: #fff;
            border-color: #009966;
        }
    }
}
/* 翻页样式结束 */

/* step 导航 */
.step-wrap {
    display: table;
    width: 100%;
    padding: 0;
    list-style: none;
    border-radius: 3px;
    margin-top: 30px;
}
.step-wrap li.step {
    font-size: 15px;
    display: table-cell;
    position: relative;
    color: #666;
    height: 60px;
    text-align: center;
    cursor: default;
}
.step-wrap li.step:before,
.step-wrap li.step:after {
    display: inline-block;
    width: 50%;
    position: absolute;
    content: " ";
    top: 16px;
    height: 4px;
    z-index: 9;
    background-color: #d8d8d8;
}
.step-wrap li.step:first-child:before {
    display: none;
}
.step-wrap li.step:last-child:after {
    display: none;
}
.step-wrap li.step:before {
    left: 0px;
}
.step-wrap li.step:after {
    right: 0px;
}
.step-wrap li.step > .step-circle {
    display: inline-block;
    position: absolute;
    z-index: 10;
    font-weight: bold;
    font-size: 16px;
    height: 34px;
    width: 34px;
    border-radius: 34px;
    line-height: 34px;
    left: 50%;
    margin-left: -17px;
    background-color: #b9b9b9;
    color: #fff;
    text-align: center;
}
.step-wrap li.step > .step-text {
    display: block;
    position: absolute;
    top: 35px;
    text-align: center;
    width: 100%;
    color: #b0b0b0;
}
.step-wrap li.step.active > .step-circle {
    background-color: #33cc33;
}
.step-wrap li.step.active > .step-text {
    color: #33cc33;
    font-weight: bold;
}
.step-wrap li.step.complete > .step-circle {
    background-color: #85e085;
}
.step-wrap li.step.complete > .step-text {
    color: #85e085;
}
.step-wrap li.step.complete:after,
.step-wrap li.step.complete:before,
.step-wrap li.step.active:before {
    background-color: #fc9c9c;
}
.currency_code{ 
    font-size:75% !important; 
    color:gray !important; 
    font-weight:bold !important; 
} 
.orders-total{ 
    font-size:150% !important; 
    font-weight:bold !important; 
    color:orange !important; 
}
@media (min-width: 1200px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1180px;
    }
}
