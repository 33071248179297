[class^="flag-"],[class*=" flag-"] {
    display: inline-block;
    width: 16px;
    height: 11px;
    vertical-align: middle;
    background-image: url(https://file.aconf.org/public/dist/media/icons/flags.png);
    background-repeat: no-repeat;
    margin-right: 0.3em;
}
#language a i{
    display: inline-block;
    width: 16px;
    height: 11px;
    vertical-align: middle;
    background-image: url(https://file.aconf.org/public/dist/media/icons/flags.png);
    background-repeat: no-repeat;
    margin-right: 0.3em;
}
.flag-ad{background-position:0 0}
.flag-ae{background-position:0 -31px}
.flag-af{background-position:0 -62px}
.flag-ag{background-position:0 -93px}
.flag-ai{background-position:0 -124px}
.flag-al{background-position:0 -155px}
.flag-am{background-position:0 -186px}
.flag-an{background-position:0 -217px}
.flag-ao{background-position:0 -248px}
.flag-ar{background-position:0 -279px}
.flag-ar-ar{background-position:0 -279px}
.flag-as{background-position:0 -310px}
.flag-at{background-position:0 -341px}
.flag-au{background-position:0 -372px}
.flag-aw{background-position:0 -403px}
.flag-ax{background-position:0 -434px}
.flag-az{background-position:0 -465px}
.flag-ba{background-position:0 -496px}
.flag-bb{background-position:0 -527px}
.flag-bd{background-position:0 -558px}
.flag-be{background-position:0 -589px}
.flag-bf{background-position:0 -620px}
.flag-bg{background-position:0 -651px}
.flag-bh{background-position:0 -682px}
.flag-bi{background-position:0 -713px}
.flag-bj{background-position:0 -744px}
.flag-bm{background-position:0 -775px}
.flag-bn{background-position:0 -806px}
.flag-bo{background-position:0 -837px}
.flag-br{background-position:0 -868px}
.flag-bs{background-position:0 -899px}
.flag-bt{background-position:0 -930px}
.flag-bv{background-position:0 -961px}
.flag-bw{background-position:0 -992px}
.flag-by{background-position:0 -1023px}
.flag-bz{background-position:0 -1054px}
.flag-ca{background-position:0 -1085px}
.flag-catalonia{background-position:0 -1116px}
.flag-cc{background-position:0 -1147px}
.flag-cd{background-position:0 -1178px}
.flag-cf{background-position:0 -1209px}
.flag-cg{background-position:0 -1240px}
.flag-ch{background-position:2px -1271px}
.flag-ci{background-position:0 -1302px}
.flag-ck{background-position:0 -1333px}
.flag-cl{background-position:0 -1364px}
.flag-cm{background-position:0 -1395px}
.flag-zh-cn{background-position:0 -1426px}
.flag-cn{background-position:0 -1426px}
.flag-co{background-position:0 -1457px}
.flag-cr{background-position:0 -1488px}
.flag-cs{background-position:0 -1519px}
.flag-cu{background-position:0 -1550px}
.flag-cv{background-position:0 -1581px}
.flag-cx{background-position:0 -1612px}
.flag-cy{background-position:0 -1643px}
.flag-cz{background-position:0 -1674px}
.flag-de{background-position:0 -1705px}
.flag-de-de{background-position:0 -1705px}
.flag-dj{background-position:0 -1736px}
.flag-dk{background-position:0 -1767px}
.flag-dm{background-position:0 -1798px}
.flag-do{background-position:0 -1829px}
.flag-dz{background-position:0 -1860px}
.flag-ec{background-position:0 -1891px}
.flag-ee{background-position:0 -1922px}
.flag-eg{background-position:0 -1953px}
.flag-eh{background-position:-36px 0}
.flag-england{background-position:-36px -31px}
.flag-er{background-position:-36px -62px}
.flag-es{background-position:-36px -93px}
.flag-es-es{background-position:-36px -93px}
.flag-et{background-position:-36px -124px}
.flag-europeanunion{background-position:-36px -155px}
.flag-fam{background-position:-36px -186px}
.flag-fi{background-position:-36px -217px}
.flag-fj{background-position:-36px -248px}
.flag-fk{background-position:-36px -279px}
.flag-fm{background-position:-36px -310px}
.flag-fo{background-position:-36px -341px}
.flag-fr{background-position:-36px -372px}
.flag-fr-fr{background-position:-36px -372px}
.flag-ga{background-position:-36px -403px}
.flag-gb{background-position:-36px -434px}
.flag-gd{background-position:-36px -465px}
.flag-ge{background-position:-36px -496px}
.flag-gf{background-position:-36px -527px}
.flag-gh{background-position:-36px -558px}
.flag-gi{background-position:-36px -589px}
.flag-gl{background-position:-36px -620px}
.flag-gm{background-position:-36px -651px}
.flag-gn{background-position:-36px -682px}
.flag-gp{background-position:-36px -713px}
.flag-gq{background-position:-36px -744px}
.flag-gr{background-position:-36px -775px}
.flag-gs{background-position:-36px -806px}
.flag-gt{background-position:-36px -837px}
.flag-gu{background-position:-36px -868px}
.flag-gw{background-position:-36px -899px}
.flag-gy{background-position:-36px -930px}
.flag-hk{background-position:-36px -961px}
.flag-hm{background-position:-36px -992px}
.flag-hn{background-position:-36px -1023px}
.flag-hr{background-position:-36px -1054px}
.flag-ht{background-position:-36px -1085px}
.flag-hu{background-position:-36px -1116px}
.flag-id{background-position:-36px -1147px}
.flag-ie{background-position:-36px -1178px}
.flag-il{background-position:-36px -1209px}
.flag-in{background-position:-36px -1240px}
.flag-io{background-position:-36px -1271px}
.flag-iq{background-position:-36px -1302px}
.flag-ir{background-position:-36px -1333px}
.flag-is{background-position:-36px -1364px}
.flag-it{background-position:-36px -1395px}
.flag-jm{background-position:-36px -1426px}
.flag-jo{background-position:-36px -1457px}
.flag-jp{background-position:-36px -1488px}
.flag-ja-jp{background-position:-36px -1488px}
.flag-ke{background-position:-36px -1519px}
.flag-kg{background-position:-36px -1550px}
.flag-kh{background-position:-36px -1581px}
.flag-ki{background-position:-36px -1612px}
.flag-km{background-position:-36px -1643px}
.flag-kn{background-position:-36px -1674px}
.flag-kp{background-position:-36px -1705px}
.flag-kr{background-position:-36px -1736px}
.flag-ko-kr{background-position:-36px -1736px}
.flag-kw{background-position:-36px -1767px}
.flag-ky{background-position:-36px -1798px}
.flag-kz{background-position:-36px -1829px}
.flag-la{background-position:-36px -1860px}
.flag-lb{background-position:-36px -1891px}
.flag-lc{background-position:-36px -1922px}
.flag-li{background-position:-36px -1953px}
.flag-lk{background-position:-72px 0}
.flag-lr{background-position:-72px -31px}
.flag-ls{background-position:-72px -62px}
.flag-lt{background-position:-72px -93px}
.flag-lu{background-position:-72px -124px}
.flag-lv{background-position:-72px -155px}
.flag-ly{background-position:-72px -186px}
.flag-ma{background-position:-72px -217px}
.flag-mc{background-position:-72px -248px}
.flag-md{background-position:-72px -279px}
.flag-me{background-position:-72px -310px}
.flag-mg{background-position:-72px -342px}
.flag-mh{background-position:-72px -373px}
.flag-mk{background-position:-72px -404px}
.flag-ml{background-position:-72px -435px}
.flag-mm{background-position:-72px -466px}
.flag-mn{background-position:-72px -497px}
.flag-mo{background-position:-72px -528px}
.flag-mp{background-position:-72px -559px}
.flag-mq{background-position:-72px -590px}
.flag-mr{background-position:-72px -621px}
.flag-ms{background-position:-72px -652px}
.flag-mt{background-position:-72px -683px}
.flag-mu{background-position:-72px -714px}
.flag-mv{background-position:-72px -745px}
.flag-mw{background-position:-72px -776px}
.flag-mx{background-position:-72px -807px}
.flag-my{background-position:-72px -838px}
.flag-mz{background-position:-72px -869px}
.flag-na{background-position:-72px -900px}
.flag-nc{background-position:-72px -931px}
.flag-ne{background-position:-72px -962px}
.flag-nf{background-position:-72px -993px}
.flag-ng{background-position:-72px -1024px}
.flag-ni{background-position:-72px -1055px}
.flag-nl{background-position:-72px -1086px}
.flag-no{background-position:-72px -1117px}
.flag-np{background-position:-68px -1148px}
.flag-nr{background-position:-72px -1179px}
.flag-nu{background-position:-72px -1210px}
.flag-nz{background-position:-72px -1241px}
.flag-om{background-position:-72px -1272px}
.flag-pa{background-position:-72px -1303px}
.flag-pe{background-position:-72px -1334px}
.flag-pf{background-position:-72px -1365px}
.flag-pg{background-position:-72px -1396px}
.flag-ph{background-position:-72px -1427px}
.flag-pk{background-position:-72px -1458px}
.flag-pl{background-position:-72px -1489px}
.flag-pm{background-position:-72px -1520px}
.flag-pn{background-position:-72px -1551px}
.flag-pr{background-position:-72px -1582px}
.flag-ps{background-position:-72px -1613px}
.flag-pt{background-position:-72px -1644px}
.flag-pw{background-position:-72px -1675px}
.flag-py{background-position:-72px -1706px}
.flag-qa{background-position:-72px -1737px}
.flag-re{background-position:-72px -1768px}
.flag-ro{background-position:-72px -1799px}
.flag-rs{background-position:-72px -1830px}
.flag-ru{background-position:-72px -1861px}
.flag-ru-ru{background-position:-72px -1861px}
.flag-rw{background-position:-72px -1892px}
.flag-sa{background-position:-72px -1923px}
.flag-sb{background-position:-72px -1954px}
.flag-sc{background-position:-108px 0}
.flag-scotland{background-position:-108px -31px}
.flag-sd{background-position:-108px -62px}
.flag-se{background-position:-108px -93px}
.flag-sg{background-position:-108px -124px}
.flag-sh{background-position:-108px -155px}
.flag-si{background-position:-108px -186px}
.flag-sj{background-position:-108px -217px}
.flag-sk{background-position:-108px -248px}
.flag-sl{background-position:-108px -279px}
.flag-sm{background-position:-108px -310px}
.flag-sn{background-position:-108px -341px}
.flag-so{background-position:-108px -372px}
.flag-sr{background-position:-108px -403px}
.flag-st{background-position:-108px -434px}
.flag-sv{background-position:-108px -465px}
.flag-sy{background-position:-108px -496px}
.flag-sz{background-position:-108px -527px}
.flag-tc{background-position:-108px -558px}
.flag-td{background-position:-108px -589px}
.flag-tf{background-position:-108px -620px}
.flag-tg{background-position:-108px -651px}
.flag-th{background-position:-108px -682px}
.flag-tj{background-position:-108px -713px}
.flag-tk{background-position:-108px -744px}
.flag-tl{background-position:-108px -775px}
.flag-tm{background-position:-108px -806px}
.flag-tn{background-position:-108px -837px}
.flag-to{background-position:-108px -868px}
.flag-tr{background-position:-108px -899px}
.flag-tt{background-position:-108px -930px}
.flag-tv{background-position:-108px -961px}
.flag-zh-tw{background-position:-108px -992px}
.flag-tw{background-position:-108px -992px}
.flag-tz{background-position:-108px -1023px}
.flag-ua{background-position:-108px -1054px}
.flag-ug{background-position:-108px -1085px}
.flag-um{background-position:-108px -1116px}
.flag-us{background-position:-108px -1147px}
.flag-en-us{background-position:-108px -1147px}
.flag-uy{background-position:-108px -1178px}
.flag-uz{background-position:-108px -1209px}
.flag-va{background-position:-108px -1240px}
.flag-vc{background-position:-108px -1271px}
.flag-ve{background-position:-108px -1302px}
.flag-vg{background-position:-108px -1333px}
.flag-vi{background-position:-108px -1364px}
.flag-vn{background-position:-108px -1395px}
.flag-vu{background-position:-108px -1426px}
.flag-wales{background-position:-108px -1457px}
.flag-wf{background-position:-108px -1488px}
.flag-ws{background-position:-108px -1519px}
.flag-ye{background-position:-108px -1550px}
.flag-yt{background-position:-108px -1581px}
.flag-za{background-position:-108px -1612px}
.flag-zm{background-position:-108px -1643px}
.flag-zw{background-position:-108px -1674px}
.flag-switch{background-position:-108px -1704px}