//global_prompt 的样式
.alert-msg{
    background: #f8edbb;
    position: fixed;
    z-index: 100003;
    left: 50%;
    color: #555555;
    font-size: 16px;
    font-weight: 500;
    height: 50px;
    display: none;
    line-height: 48px;
    top: 100px;
    padding:0px 30px;
    border-radius: 2px;
    border:1px solid #f0c368;
    -moz-box-shadow:0px 2px 4px #cccccc;/*firefox*/  
    -webkit-box-shadow:0px 2px 4px #cccccc;/*webkit*/  
    box-shadow:0px 2px 4px #cccccc;/*opera或ie9*/  
}