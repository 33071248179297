.footer {
    background: #f4f4f4;
    border-top: 1px solid #d3d3d3;
    color: #333;
    font-size: .875rem;
    .footer_content {
        padding: 2rem 0;
        position: relative;
        .footer_copyright {
            p.clause {
                a {
                    color: #333;
                    display: inline-block;
                }
                span {
                    display: inline-block;
                    padding: 0 0.25rem;
                }
            }
            p.aconf_link{
                margin-bottom: 0;
            }
            p.copy_right {
                margin-bottom: 0;
            }
        }
        .footer_wx {
            position: absolute;
            top: 1rem;
            right: 0;
            z-index: 10;
            img {
                width: 90px;
                height: 90px;
                display: block;
                margin: 0 auto;
            }
            span {
                color: #999;
                font-size: .875rem;
                display: block;
                text-align: center;
            }
        }
    }
}
@media(max-width:767px){
    .footer .footer_content .footer_wx{
        display: none;
    }
}