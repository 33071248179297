@import './components/dropdown';
@import './old/prompt';
@import './old/va-error';
/* 订单状态 */
[class^="order_status_"], [class*=" order_status_"] {
    background-color: #777 ;color: #fff;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    border: 1px solid transparent;
    padding: 2px 3px;
    font-size: 14px;
    line-height: 1;
}
.order_status_0{
    color: #fff;
    background-color: #b3b3b3;
    border: 1px solid #666;
}
.order_status_1{
    color: #fff;
    background-color: #ffc773;
    border: 1px solid #ff9900;
}
.order_status_2{
    color: #fff;
    background-color: #5ab594;
    border: 1px solid #21A675;
}
.order_status_3{
    color: #fff;
    background-color: #096;
    border: 1px solid #006a47;
}
.order_status_4{
    color: #fd5451;
    background-color: #fff;
    border: 1px solid #fd5451;
}
.order_status_5{
    color: #ff3300;
    background-color: #fff;
    border: 1px solid #ff3300;
}
.order_status_6{
    color: #1c9abd;
    background-color: #fff;
    border: 1px solid #1c9abd;
}
.order_status_7{
    color: #fff;
    background-color: #feaaa8;
    border: 1px solid #fd5451
}
.order_status_8{
    color: #fff;
    background-color: #a0c0b4;
    border: 1px solid #24986d;
}
.order_status_9{
    color: #f24989;
    background-color: #fff;
    border: 1px solid #f24989;
}
.order_status_10{
    color: #21a675;
    background-color: #fff;
    border: 1px solid #21a675;
}
.order_status_11{
    color: #669999;
    background-color: #fff;
    border: 1px solid #669999;
}
.order_status_free{
    color: #fff;
    background-color: #8BC34A;
    border: 1px solid #096;
}
/* 订单状态结束 */

/* 论文状态 */
[class^="submission_status_"], [class*=" submission_status_"] {
	background: url("../../media/icons/submission_status.png") center center no-repeat;
    display: inline-block;
	width: 16px;
	height: 16px;
	line-height: 16px;
	margin-right: 3px;
	vertical-align: middle;
}
.submission_status_0 {
    background-position:-0px 0px;
}
.submission_status_1 {
    background-position:-16px 0;
}
.submission_status_2 {
    background-position:-32px 0;
}
.submission_status_3 {
    background-position:-48px 0;
}
.submission_status_4 {
    background-position:-64px 0;
}
.submission_status_5 {
    background-position:-80px 0;
}
.submission_status_6 {
    background-position:-96px 0;
}
.submission_status_7 {
    background-position:-112px 0;
}
.submission_status_add {
    background-position:-128px 0;
}
.submission_status_edit {
    background-position:-144px 0;
}
.submission_status_upload {
    background-position:-160px 0;
}
.submission_status_delete {
    background-position:-176px 0;
}
.submission_status_withdraw {
    background-position:-192px 0;
}
.submission_status_affiliate_author {
    background-position:-208px 0;
}
/* 论文状态结束 */

 /* 无图标稿件状态 */
 [class^="abstract_status_"], [class*="abstract_status_"] {
    color: #096;
    border: 1px solid #096;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    padding: 2px 4px;
    font-size: 12px;
    line-height: 1;
}
.abstract_status_-1 {
    color: #dc3545;
    border: 1px solid #dc3545;
    text-decoration: line-through;
}
.abstract_status_0 {
    border-color: #999;
    color: #999;
}
.abstract_status_1 {
    border-color: red;
    color: red;
}
.abstract_status_2 {
    border-color: #17a2b8;
    color: #17a2b8;
}
.abstract_status_3 {
    border-color: #fd7e14;
    color: #fd7e14;
}
.abstract_status_4 {
    border-color: #9c27b0;
    color: #9c27b0;
}
.abstract_status_5 {
    border-color: #de1102;
    color: #de1102;
}
.abstract_status_6 {
    border-color: #096;
    color: #096;
}
.abstract_status_7 {
    border-color: #096;
    color: #fff;
    background: #096;
}
.abstract_status_add {
    border-color: #eab518;
    color: #eab518;
}
.abstract_status_edit {
    border-color: #8bc34a;
    color: #8bc34a;
}
.abstract_status_upload {
    border-color: #f100d1;
    color: #f100d1;
}
.abstract_status_delete {
    border-color: #9c9c9c;
    color: #9c9c9c;
}
.abstract_status_affiliate_author {
    border-color: #006977;
    color: #006977;
}
/* 无图标稿件状态结束 */
/* 翻页 */
.pagination{
    -webkit-justify-content:center;
    justify-content: center;
}
.page-item{
    margin: 0 .25rem;
}
.page-link{
    color: #21A675;
    background-color: #fff;
}
.page-item.active .page-link{
    background-color: #21A675;
    border-color: #21A675;
}
.page-outline{
    line-height: 38px;
}
/* 翻页结束 */

/* 按钮 */
.btn-aconf-border{
    background-color: #f5f5f5;
    border-color: #21A675;
    color: #21A675;
}
.btn-aconf-border:hover{
    background-color: #24986d;
    border-color: #24986d;
    color: #fff;
}
.btn-aconf-default{
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c;
}
.btn-aconf-default:hover{
    color: #333;
    background-color: #d4d4d4;
    border-color: #adadad;
}
.btn-aconf-primary{
    background-color: #21A675;
    border-color: #21A675;
    color: #fff;
}
.btn-aconf-primary:hover{
    background-color: #24986d;
    border-color: #24986d;
    color: #fff;
}
.btn-aconf-danger{
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
}
.btn-aconf-danger:hover{
    background-color: #c3303e;
    border-color: #c3303e;
    color: #fff;
}
.btn-aconf-warning{
    background-color: #ffc107;
    border-color: #ffc107;
    color: #fff;
}
.btn-aconf-warning:hover{
    background-color: #dea808;
    border-color: #dea808;
    color: #fff;
}
.btn-aconf-disabled{
    color: #666;
    background-color: #ddd;
    border-color: #ddd;
    cursor: not-allowed !important;
}
.btn-aconf-disabled:hover{
    color: #666;
    background-color: #ddd;
    border-color: #ddd;
}
.btn-aconf-border:hover,.btn-aconf-border:active,.btn-aconf-border:focus{
    background-color: #24986d;
    border-color: #24986d;
    color: #fff;
}
.btn-aconf-green:hover,.btn-aconf-green:active,.btn-aconf-green:focus{
    background-color: #24986d;
    border-color: #24986d;
    color: #fff;
}
/* 下拉菜单hover */
.drop_menu_hover{
    position: relative;
}
.drop_menu_hover ul{
    list-style: none;
    padding-left: 0;
}
.drop_menu_btn::after{
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.drop_menu_btn:hover::after{
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent;
}
.sub_menu_hover{
    display: none;
    position: absolute;
    left: 0;
    z-index: 999;
    text-align: center;
    min-width: 100%;
    border: 1px solid #21A675;
}
.sub_menu_hover li a{
    display: block;
    color: #21A675;
    background: #fff;
    white-space: nowrap;
    padding: 10px 0;
    font-size: 16px;
    text-decoration: none;
}
.sub_menu_hover li a:hover{
    background: #24986d;
    color: #fff;
}

/* 下拉菜单active */
.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #24986d;
}
.dropdown-item:hover{
    color: #fff;
    text-decoration: none;
    background-color: #24986d;
}

/* 表单控件 */
.btn {
    font-size: .875rem;
}
.form-group {
    margin-bottom: .75rem;
}
.form-group label {
    font-size: .875rem;
}
.form-control {
    font-size: .875rem;
}
.form-control:focus, .textarea:focus {
    border: 1px solid #096;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/* color-picker */
.color-picker-box{
    position: relative;
}
.color-picker-box .palette-picker{
    list-style: none;
    display: none;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ddd;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 999;
    margin-top: 5px;
    margin-bottom: 0;
}
.color-picker-box .palette-picker li{
    overflow: hidden;
}
.color-picker-box .palette-color{
    display: block;
    cursor: pointer;
    float: left;
    margin: 2px;
}
.color-picker-box .background-box{
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.color-picker-box .text-box{
    width: 40%;
    height:40%;
    margin: 0 auto;
    border-radius: 50%;
}
.color-picker-box .color-picker-btn{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    font-size: 1rem;
    display: inline-block;
    line-height: 3rem;
    text-decoration: none;
    background: #096;
    color: #fff;
}
/* 弹出框样式 */
.modal-header {
    padding: .25rem .5rem; 
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 1rem;
}
.modal-body {
    padding: .75rem 1rem;
}
.modal-header .close {
    padding: 0;
    margin: 0;
    font-size: 1.25rem;
    outline: 0;
}
/* 参会人类型 */
[class^="ATTENDEE_TYPE_"], [class*=" ATTENDEE_TYPE_"] {
    background-color: #096 ;
    color: #fff;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    padding: 4px 6px;
    font-size: 14px;
    line-height: 1;
}
.ATTENDEE_TYPE_ATTENDEE {
    background-color: #096;
    color: #fff;
}
.ATTENDEE_TYPE_EXHIBITOR {
    background-color: #007bff;
    color: #fff;
}
.ATTENDEE_TYPE_SPEAKER {
    background-color: #dc3545;
    color: #fff;
}
.ATTENDEE_TYPE_VISITOR {
    background-color: #17a2b8;
    color: #fff;
}
.ATTENDEE_TYPE_VOLUNTEER {
    background-color: #fd7e14;
    color: #fff;
}
.ATTENDEE_TYPE_KEYNOTE_SPEAKER {
    background-color: #9c27b0;
    color: #fff;
}
.ATTENDEE_TYPE_INVITED_SPEAKER {
    background-color: #ffa500;
    color: #fff;
}
.ATTENDEE_TYPE_CONFERENCE_CHAIR {
    background-color: #6610f2;
    color: #fff;
}
.ATTENDEE_TYPE_VIP {
    background: #e8e80f;
    color: #7f6e0e;
}
.ATTENDEE_TYPE_STAFF {
    background-color: #4caf50;
    color: #fff;
}
.ATTENDEE_TYPE_PC_CHAIR {
    background-color: #ff5722;
    color: #fff;
}
.ATTENDEE_TYPE_MEDIA {
    background-color: #cddc39;
    color: #fff;
}
.ATTENDEE_TYPE_EXT1 {
    background-color: #9c9c9c;
    color: #fff;
}
.ATTENDEE_TYPE_EXT2 {
    background-color: #9c9c9c;
    color: #fff;
}
.ATTENDEE_TYPE_EXT3 {
    background-color: #9c9c9c;
    color: #fff;
}

[class^="CONTRIBUTION_TYPE_"], [class*=" CONTRIBUTION_TYPE_"] {
    color: #096;
    border: 1px solid #096;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    padding: 2px 4px;
    font-size: 12px;
    line-height: 1;
}
.CONTRIBUTION_TYPE_ORAL {
    border: 1px solid #096;
    color: #096;
}
.CONTRIBUTION_TYPE_POSTER {
    border: 1px solid #007bff;
    color: #007bff;
}
.CONTRIBUTION_TYPE_KEYNOTE {
    border: 1px solid #9c27b0;
    color: #9c27b0;
}
.CONTRIBUTION_TYPE_INVITED {
    border: 1px solid #ffa500;
    color: #ffa500;
}
.CONTRIBUTION_TYPE_DEMO {
    border: 1px solid #fd7e14;
    color: #fd7e14;
}
.CONTRIBUTION_TYPE_WELCOME {
    border: 1px solid #4caf50;
    color: #4caf50;
}
.CONTRIBUTION_TYPE_CLOSING {
    border: 1px solid #ff5722;
    color: #ff5722;
}
.CONTRIBUTION_TYPE_SUMMARY {
    border: 1px solid #6610f2;;
    color: #6610f2;;
}
.CONTRIBUTION_TYPE_EXT1 {
    border: 1px solid #9c9c9c;
    color: #9c9c9c;
}
.CONTRIBUTION_TYPE_EXT2 {
    border: 1px solid #9c9c9c;
    color: #9c9c9c;
}
.CONTRIBUTION_TYPE_EXT3 {
    border: 1px solid #9c9c9c;
    color: #9c9c9c;
}

[class^="SESSION_ATTR_"], [class*=" SESSION_ATTR_"] {
    color: #606060;
    border: 1px solid #606060;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    padding: 2px 4px;
    font-size: 12px;
    line-height: 1;
}